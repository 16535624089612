import {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {formatDateTime} from "../../helpers/date-helpers";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {useSessionStorage} from "../../hooks/general/useSessionStorage";
import paths from "../../navigation/paths";
import {inspectionStrings as strings} from "../../resources/strings/inspections";
import {api} from "../../services/inspections.service";
import {FilterChangeHandler, RouteProps} from "../../types";
import type {InspectionFilters} from "../../types/documents/Inspection";
import {Inspection} from "../../types/documents/Inspection";
import {TableContainer} from "../general/TableContainer";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const InspectionsList: Component = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<InspectionFilters>({}, "inspectionFilters");

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  const tableFilters = useMemo(() => ({...filters}), [filters]);
  const cancelToken = useCancelToken();
  const history = useNavigate();

  return (
    <div>
      <TableContainer<Inspection>
        {...routeProps}
        api={api}
        tableColumns={[
          {
            id: "inspectionDate",
            Header: strings.labels.inspectionDate,
            accessor: (row) => formatDateTime(row.CreatedDate ? row.CreatedDate : ""),
          },
          {
            id: "filterGTIN",
            Header: strings.labels.filterGTIN,
            accessor: "FilterGTIN",
          },
          {
            id: "filterSerialNumber",
            Header: strings.labels.filterSerialNumber,
            accessor: "FilterSerialNumber",
          },
        ]}
        children={[]}
        manualPagination={true}
        filters={tableFilters as any}
        clickHandler={(inspection) => history(paths.inspections.modify(inspection.Id.toString()))}
      ></TableContainer>
    </div>
  );
};

export default InspectionsList;
