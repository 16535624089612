import type { SxProps, Theme } from "@mui/material";
import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { detailErrorResponse } from "../../helpers/control-helpers";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import type { FileOutputFormat, FileReaderResult } from "../../helpers/file-parser";
import { useToken } from "../../hooks/authentication/useToken";
import { useCancelToken } from "../../hooks/general/useCancelToken";
import { notifications } from "../../libs/notifications";
import { appStrings as strings } from "../../resources/strings/app";
import { Dropdown } from "./controls/Dropdown";

interface Props {
  maxSize: number;
  allowedFileTypes: string[];
  allowedFileExtensions: string[];
  handleUpload: (file: FileReaderResult) => Promise<void>;
  outputFormat?: FileOutputFormat;
  api: any;
  entity: string;
  categoryDropdownOptions: any;
}
type Component = (props: Props) => JSX.Element;

const rootStyles: SxProps<Theme> = (theme) => ({
  backgroundColor: "#FAFAFA",
  border: theme.spacing(0.1, "dashed", `${"#CCCCCC"}`),
  "&:hover": {
    cursor: "pointer",
  },
});

export const FileUpload: Component = ({maxSize, allowedFileTypes, allowedFileExtensions, handleUpload, outputFormat, api, entity, categoryDropdownOptions}) => {
  const fileInput = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(categoryDropdownOptions[0]?.value ?? "");
  const [selectedVisibleOnMobile, setSelectedVisibleOnMobile] = useState("true");
  const onUpload = useCallback(handleUpload, [handleUpload]);

  const maxSizeInBytes = useMemo(() => maxSize * 1024, [maxSize]);

  const isTooLarge = useMemo(() => {
    if (!file) return false;
    return file.size > maxSizeInBytes;
  }, [file, maxSizeInBytes]);

  const isValidFileType = useMemo(() => {
    if (!file) return false;
    console.log("This file " + file.name + " has file type: " + file.type);

    const matchingExtensions = allowedFileExtensions.filter((x) => file.name.toLowerCase().endsWith(x.toLowerCase()));
    if (matchingExtensions.length === 0) {
      return false;
    }

    return allowedFileTypes.includes(file.type);
  }, [file, allowedFileTypes, allowedFileExtensions]);

  const jwt = useToken();
  const cancelToken = useCancelToken();

  useEffect(() => {
    if (!file || isTooLarge || !isValidFileType) return;

    const payload = {
      file,
      categoryid: selectedCategoryId,
      visibleonmobile: selectedVisibleOnMobile
    };

    const uploadFile = async () => {
      api
        .upload(entity === "document" ? payload : file, jwt, cancelToken)
        .then(() => window.location.reload())
        .catch((error: any) => {
          const errorInformation = detailErrorResponse(error);
          setError(errorInformation);
          notifications.error(errorInformation);
        });
    };

    uploadFile();
  }, [file, isTooLarge, isValidFileType, onUpload, outputFormat]);


  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const {files} = e.target;
    if (!files) return;
    setFile(files[0]);
  };

  const onDrop: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    const {files} = e.dataTransfer;
    if (!files) return;
    setFile(files[0]);
  };

  const onDragEnter: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  };

  const onDragOver: React.DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
  };

  const onClick = () => {
    if (!fileInput.current) return;
    fileInput.current.click();
  };

  const renderFileText = () => {
    if (!file) return strings.labels.uploadFile;
    if (isTooLarge) return strings.errors.fileTooLarge(file.name);
    if (!isValidFileType) return strings.errors.invalidFileType(file.name);
    if (error) return `Error Uploading File - ${error}`;
    return file.name;
  };

  const booleanOptions = createDropdownOptions([{value: "true", name: "Yes"}, {value: "false", name: "No"}], "value", "name");

  return (
    <>
    {entity === "document" ? 
      <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
      <Dropdown
      config={{ name: "", label: strings.labels.selectCategory, value: selectedCategoryId, options: categoryDropdownOptions, style: { width: "200px"} }}
      handleChange={(event) => { setSelectedCategoryId(event.target.value as string) }}
      />
      <Dropdown
      config={{ name: "", label: strings.labels.selectVisibleOnMobile, value: selectedVisibleOnMobile, options: booleanOptions, defaultValue: "none", style: { width: "200px"} }}
      handleChange={(event) => { setSelectedVisibleOnMobile(event.target.value as string) }}
      />
      </div>
      : <></>}
    <Box
      sx={[rootStyles]}
      display="flex"
      height={"100%"}
      width={"100%"}
      padding={4}
      justifyContent="center"
      onClick={onClick}
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      data-testid="file-upload-root"
    >
      <input data-testid="file-upload-input" ref={fileInput} accept={allowedFileTypes.join()} type="file" hidden multiple={false} onChange={onChange} />
      {renderFileText()}
    </Box>
    </>
  );
};
