import {Route, Routes} from "react-router-dom";
import AssetList from "../Containers/assets/AssetList";
import AssetModify from "../Containers/assets/AssetModify";
import AuditTrailList from "../Containers/auditTrail/AuditTrailList";
import ClientList from "../Containers/client/ClientList";
import ClientModify from "../Containers/client/ClientModify";
import DocumentsList from "../Containers/documents/DocumentsList";
import FilterTypesList from "../Containers/filterTypes/FilterTypesList";
import {FilterTypesModify} from "../Containers/filterTypes/FilterTypesModify";
import ImportsList from "../Containers/imports/ImportsList";
import InspectionsList from "../Containers/inspections/InspectionList";
import {InspectionsModify} from "../Containers/inspections/InspectionModify";
import LocationsList from "../Containers/locations/LocationsList";
import {BuildingsModify} from "../Containers/locations/buildings/BuildingsModify";
import {FloorsModify} from "../Containers/locations/floors/FloorsModify";
import {RoomsModify} from "../Containers/locations/rooms/RoomsModify";
import {SitesModify} from "../Containers/locations/sites/SitesModify";
import {ZonesModify} from "../Containers/locations/zones/ZonesModify";
import TasksList from "../Containers/tasks/TasksList";
import {TasksModify} from "../Containers/tasks/TasksModify";
import LogIn from "../Containers/users/LogIn";
import PasswordReset from "../Containers/users/PasswordReset";
import UsersList from "../Containers/users/UsersList";
import {UsersModify} from "../Containers/users/UsersModify";
import Dashboard from "../pages/Dashboard";
import paths from "./paths";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path={paths.root} element={<Dashboard permissions={null} user={null} />} />
      <Route path={paths.passwordReset.passwordReset} element={<PasswordReset permissions={null} user={null} mode={"create"} />} />
      <Route path={paths.login.login} element={<LogIn permissions={null} user={null} mode={"create"} />} />
      <Route path={paths.client.list} element={<ClientList />} />
      <Route path={paths.filterTypes.list} element={<FilterTypesList permissions={null} user={null} />} />
      <Route path={paths.filterTypes.create} element={<FilterTypesModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.filterTypes.edit(":id")} element={<FilterTypesModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.users.list} element={<UsersList permissions={null} user={null} />} />
      <Route path={paths.users.create} element={<UsersModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.tasks.list} element={<TasksList permissions={null} user={null} />} />
      <Route path={paths.users.edit(":id")} element={<UsersModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.tasks.create} element={<TasksModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.tasks.modify(":id")} element={<TasksModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.locations.list} element={<LocationsList mode={"create"} setSelectedLocation={undefined} listPage={true} />} />
      <Route path={paths.client.create} element={<ClientModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.client.modify(":id")} element={<ClientModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.site.create} element={<SitesModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.site.modify(":id")} element={<SitesModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.building.create(":parentId")} element={<BuildingsModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.building.modify(":id")} element={<BuildingsModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.zone.create(":parentId")} element={<ZonesModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.zone.modify(":id")} element={<ZonesModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.floor.create(":parentId")} element={<FloorsModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.floor.modify(":id")} element={<FloorsModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.room.create(":parentId")} element={<RoomsModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.room.modify(":id")} element={<RoomsModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.import.list} element={<ImportsList permissions={null} user={null} status={""} />} />
      <Route path={paths.import.uploadingData(":id")} element={<ImportsList permissions={null} user={null} status={"uploadingData"} />} />
      <Route path={paths.import.processingData(":id")} element={<ImportsList permissions={null} user={null} status={"processingData"} />} />
      <Route path={paths.import.uploadFailed(":id")} element={<ImportsList permissions={null} user={null} status={"uploadFailed"} />} />
      <Route path={paths.import.processingFailed(":id")} element={<ImportsList permissions={null} user={null} status={"processingFailed"} />} />
      <Route path={paths.import.pendingApproval(":id")} element={<ImportsList permissions={null} user={null} status={"pendingApproval"} />} />
      <Route path={paths.import.validationFailed(":id")} element={<ImportsList permissions={null} user={null} status={"validationFailed"} />} />
      <Route path={paths.documents.list} element={<DocumentsList permissions={null} user={null} />} />
      <Route path={paths.dashboard} element={<Dashboard permissions={null} user={null} />} />
      <Route path={paths.home.list} element={<Dashboard permissions={null} user={null} />} />
      <Route path={paths.assets.list} element={<AssetList permissions={null} user={null} />} />
      <Route path={paths.assets.create} element={<AssetModify mode={"create"} permissions={null} user={null} />} />
      <Route path={paths.assets.modify(":id")} element={<AssetModify mode={"update"} permissions={null} user={null} />} />
      <Route path={paths.inspections.list} element={<InspectionsList permissions={null} user={null} />} />
      <Route path={paths.inspections.modify(":id")} element={<InspectionsModify permissions={null} user={null} mode="view" />} />
      <Route path={paths.auditTrail.list} element={<AuditTrailList permissions={null} user={null} />} />
    </Routes>
  );
};

export default AppRoutes;
