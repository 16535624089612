import {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {useSessionStorage} from "../../hooks/general/useSessionStorage";
import {issueStrings as strings} from "../../resources/strings/issues";
import {api} from "../../services/issues.service";
import {FilterChangeHandler, RouteProps} from "../../types";
import type {IssueFilters} from "../../types/documents/Issue";
import {Issue} from "../../types/documents/Issue";
import {TableContainer} from "../general/TableContainer";

interface Props extends RouteProps {}
type Component = (props: Props) => JSX.Element;

const IssuesList: Component = ({...routeProps}) => {
  const [filters, setFilters] = useSessionStorage<IssueFilters>({}, "issueFilters");

  const handleFilterChange: FilterChangeHandler = (name, value) =>
    setFilters((currentFilters) => ({
      ...currentFilters,
      [name]: value,
    }));

  const clearFilters = () => setFilters({});

  const tableFilters = useMemo(() => ({...filters}), [filters]);
  const cancelToken = useCancelToken();
  const history = useNavigate();

  return (
    <div>
      <TableContainer<Issue>
        {...routeProps}
        api={api}
        tableColumns={[
          {
            id: "issueType",
            Header: strings.labels.issueType,
            accessor: "IssueType",
          },
          {
            id: "description",
            Header: strings.labels.description,
            accessor: "Description",
          },
        ]}
        children={[]}
        manualPagination={true}
        filters={tableFilters as any}
        clickHandler={() => {}}
      ></TableContainer>
    </div>
  );
};

export default IssuesList;
