import {AxiosRequestConfig} from "axios";
import axiosFilter from "../libs/axios/axios-filter";
import axios from "../libs/axios/axios-inspection";
import {CreateFunction, CreateResult, QueryFunction, ReadFunction, UpdateFunction, UpdateResult} from "../types/API";
import {Inspection, InspectionDto, InspectionFilters} from "../types/documents/Inspection";

const query: QueryFunction<Inspection> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const assetId = window.location.pathname.split("/")[3];
  const path = "/asset/" + assetId;
  const filters = query as InspectionFilters;
  const {id, desc} = sort[0] ?? {};
  const config: AxiosRequestConfig = {
    params: {},
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };

  let inspections: Inspection[] = [];

  try {
    const response = await axios.get<Inspection[]>(path, config);

    for (const inspection of response.data) {
      const filter = await axiosFilter.get(`/${inspection.FilterId}`, config);

      inspection.FilterGTIN = filter.data.Gtin;
      inspection.FilterSerialNumber = filter.data.SerialNumber;

      inspections.push(inspection);
    }

    return {
      items: inspections,
      totalCount: inspections.length,
    };
  } catch (error) {
    return {
      items: [],
      totalCount: 0,
    };
  }
};

const read: ReadFunction<Inspection> = async (id, _childId, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };
  const {data} = await axios.get<InspectionDto>(path, config);

  const filter = await axiosFilter.get(`/${data.FilterId}`, config);

  data.FilterGTIN = filter.data.Gtin;
  data.FilterSerialNumber = filter.data.SerialNumber;

  console.log(data);

  if (data.InspectionImageId !== null) {
    const image = await axios.get(`${path}/image/upload`, {...config, responseType: "blob"});
    data.Photo = image.data;
  }

  return {
    item: {...data},
  };
};

const create: CreateFunction<Inspection> = async ({payload}, jwt?, cancelToken?) => {
  const inspection = payload;
  const config: AxiosRequestConfig = {
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };
  inspection.Id = "00000000-0000-0000-0000-000000000000";
  const {data} = await axios.post<CreateResult>("/inspections", inspection, config);
  return data;
};

const update: UpdateFunction<Inspection> = async ({payload}, jwt?, cancelToken?) => {
  const inspection = payload;
  const {Id} = inspection;
  const path = `/inspections/${Id}`;
  const config: AxiosRequestConfig = {
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };
  const {data} = await axios.put<UpdateResult>(path, inspection, config);
  return data;
};

export const api = {
  query,
  read,
  create,
  update,
};
