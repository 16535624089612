import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {detailErrorResponse} from "../../helpers/control-helpers";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {notifications} from "../../libs/notifications";
import {paths} from "../../navigation/paths";
import {inspectionStrings as strings} from "../../resources/strings/inspections";
import {api} from "../../services/inspections.service";
import type {Inspection} from "../../types/documents/Inspection";
import {BreadcrumbHeader} from "../general/BreadcrumbHeader";
import {CreateButtons} from "../general/CreateButtons";
import {Header} from "../general/Header";
import type {CustomProps} from "../general/types/Modify";
import {UpdateDeleteButtons} from "../general/UpdateDeleteButtons";

interface Props extends CustomProps<Inspection> {}
type Component = (props: Props) => JSX.Element;

export const InspectionBreadcrumbHeader: Component = ({mode}) => {
  const {id} = useParams<{id?: string}>();
  const jwt = useToken();
  const cancelToken = useCancelToken();
  const history = useNavigate();
  const [toDelete, setToDelete] = useState(false);

  const handleBack = () => {
    history(paths.inspections.list);
  };


  return (
    <BreadcrumbHeader
      header={<><Header text={strings.headers.inspection} /></>}
      children={<></>}
    />
  );
};
