import type {Config, ConfigurationProps, ContentConfig, Mode} from "../../components/general/types/Modify";
import {InspectionBreadcrumbHeader} from "../../components/inspection/inspectionBreadcrumbHeader";
import {InspectionPhoto} from "../../components/inspection/inspectionPhoto";
import {inspectionStrings as strings} from "../../resources/strings/inspections";
import {Inspection} from "../../types/documents/Inspection";

const headerConfig: Config<Inspection> = [
  {
    key: "header",
    content: [
      {
        controltype: "custom",
        Component: InspectionBreadcrumbHeader,
        md: 12,
        control: false,
      },
    ],
  },
];

const inspectionContent = (mode: Mode, inspectionData: Inspection): ContentConfig<Inspection>[] => {
  const commonConfig: ContentConfig<Inspection>[] = [
    {
      controltype: "header",
      text: strings.headers.inspectionDetails,
    },
    {
      controltype: "input",
      name: "Description",
      label: strings.labels.description,
    },
    {
      controltype: "input",
      name: "FilterGTIN",
      label: strings.labels.filterGTIN,
    },
    {
      controltype: "input",
      name: "FilterSerialNumber",
      label: strings.labels.filterSerialNumber,
    },
    {
      controltype: "input",
      name: "AssetBarcode",
      label: strings.labels.assetBarcode,
    },
    {
      controltype: "header",
      text: strings.headers.inspectionPhoto,
    },

    {
      controltype: "custom",
      Component: (row) => {
        return <InspectionPhoto user={null} permissions={null} photo={row.data.Photo} />;
      },
      md: 6,
      control: true,
    },
    {
      controltype: "input",
      name: "PhotoNotes",
      label: strings.labels.photoNotes,
    },

    {
      controltype: "header",
      text: strings.headers.issues,
    },
  ];

  const config = commonConfig;
  return config;
};

export const InspectionConfig = (props: ConfigurationProps<Inspection>, jwt: string): Config<Inspection> => {
  const {data, mode} = props;

  const inspectionInfoConfig = [
    {
      key: "fields",
      content: inspectionContent(mode, data),
    },
  ];

  return [...headerConfig, ...inspectionInfoConfig];
};
