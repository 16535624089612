import {useEffect, useState} from "react";
import {inspectionStrings as strings} from "../../resources/strings/inspections";
import {Inspection} from "../../types/documents/Inspection";
import {CustomProps} from "../general/types/Modify";

interface Props extends Partial<CustomProps<Inspection>> {
  photo?: Blob; // This can either be a base64 string or binary data.
}
type Component = (props: Props) => JSX.Element;

const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string); // Base64 string
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const InspectionPhoto: Component = ({photo}) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    if (!photo) return;
    const loadImage = async () => {
      try {
        const base64Image = await blobToBase64(photo);
        setImageSrc(base64Image as any); // This is a base64 string you can use directly in <img>
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [photo]);

  return <div style={styles.container}>{imageSrc ? <img src={imageSrc} alt="Inspection" style={styles.image} /> : <p>{strings.text.inspectionHasNoPhoto}</p>}</div>;
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "left",
    alignItems: "left",
    margin: "20px",
  },
  image: {
    maxWidth: "100%",
    height: "250px",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
};
