export const inspectionStrings = {
  headers: {
    inspectionList: "Inspections",
    inspectionDetails: "Inspection Details",
    inspectionPhoto: "Inspection Photo",
    issues: "Issues",
    inspection: "Inspection",
  },
  labels: {
    search: "Search Inspections",
    inspectionDate: "Inspection Date",
    filterGTIN: "Filter GTIN",
    filterSerialNumber: "Filter Serial No.",
    issueCount: "Issue Count",
    description: "Description",
    assetBarcode: "Asset Barcode",
    assetLocation: "Asset Location",
    photoNotes: "Photo Notes",
  },
  placeholders: {},
  text: {
    inspectionHasNoPhoto: "This inspection has no photo",
  },
};
