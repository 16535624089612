import {useCallback} from "react";
import type {Mode} from "../../components/general/types/Modify";
import {useClientContext} from "../../context/ClientContext";
import {createDropdownOptions} from "../../helpers/dropdown-helpers";
import {useToken} from "../../hooks/authentication/useToken";
import {useCancelToken} from "../../hooks/general/useCancelToken";
import {paths} from "../../navigation/paths";
import {inspectionStrings as strings} from "../../resources/strings/inspections";
import {api} from "../../services/inspections.service";
import type {RouteProps, ValidationConstraints} from "../../types";
import {Inspection} from "../../types/documents/Inspection";
import {ModifyContainer} from "../general/ModifyContainer";
import {InspectionConfig} from "./InspectionConfig";
import IssuesList from "../issues/IssueList";
interface Props extends RouteProps {
  mode: Mode;
}
type Component = (props: Props) => JSX.Element;
type ListItems = {[key: string]: any}[];

export const InspectionsModify: Component = ({mode, ...routeProps}) => {
  const jwt = useToken();

  const inspectionConstraints: ValidationConstraints<Inspection> = {
    
  };

  return (
    <div>
      <ModifyContainer<Inspection>
        {...routeProps}
        api={api}
        componentConfiguration={(props) => InspectionConfig(props,  jwt)}
        constraints={inspectionConstraints}
        initialData={{
            Id: "",
            InspectionDate: "",
            FilterGTIN: "",
            FilterSerialNumber: "",
            IssueCount: 0,
            Description: "",
            AssetBarcode: "",
            AssetLocation: "",
            PhotoNotes: "",
          
        }}
        mode={mode}
        redirectPath={paths.inspections.list}
      />
      <IssuesList {...routeProps} />
    </div>
  );
};
