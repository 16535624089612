import {AxiosRequestConfig} from "axios";
import axios from "../libs/axios/axios-issue";
import {QueryFunction} from "../types/API";
import {Issue, IssueFilters} from "../types/documents/Issue";

const query: QueryFunction<Issue> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const path = "/issues/search";
  const filters = query as IssueFilters;
  const {id, desc} = sort[0] ?? {};
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      page: page + 1,
      size: size,
      sort: id,
      order: desc ? "desc" : "asc",
    },
    headers: {Authorization: jwt ? jwt : ""},
    cancelToken,
  };

  const {data} = await axios.get<{issues: Issue[]; count: number}>(path, config);
  /*const result = [
    {
        Id: "1",
        IssueType: "Example Issue Type 1",
        Description: "Example Issue Description 1",
    },
    {
        Id: "2",
        IssueType: "Example Issue Type 2",
        Description: "Example Issue Description 2",
    },
    {
        Id: "3",
        IssueType: "Example Issue Type 3",
        Description: "Example Issue Description 3",
    },
    {
        Id: "4",
        IssueType: "Example Issue Type 4",
        Description: "Example Issue Description 4",
    }
  ]*/

  return {
    items: data.issues,
    totalCount: data.count,
  };
};

export const api = {
  query,
};
