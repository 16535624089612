import { AxiosRequestConfig } from "axios";
import axios from "../libs/axios/axios-document";
import { DeleteFunction, DownloadDocumentFunction, QueryFunction } from "../types/API";
import { Category, Document, DocumentFilters } from "../types/documents/Document";

const query: QueryFunction<Document> = async ({page = 0, size = 10, sort = [], query}, jwt?, cancelToken?) => {
  const path = "";
  const {id, desc} = sort[0] ?? {};
  const filters = query as DocumentFilters;
  const config: AxiosRequestConfig = {
    params: {
      ...filters,
      active: "true",
      page: page + 1,
      size,
      sort: id,
      order: desc ? "desc" : "asc",
    },
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };

  type DocumentListResult = {
    items: Document[];
    count: number;
  };
  const {data} = await axios.get<DocumentListResult>(path, config);
  return {
    items: data.items,
    totalCount: data.count,
  };
};

const download: DownloadDocumentFunction = async (id, jwt?, cancelToken?) => {
  console.log("downloading");
  const config: AxiosRequestConfig = {
    params: {},
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
    responseType: 'blob',
  };
  const path = "/download/" + id;
  const response = await axios.get(path, config);
  return response.data;
};

const upload = async (payload: any, jwt?: any, cancelToken?: any) => {
  const path = `/upload`;
  const formData = new FormData();
  formData.append("file", payload.file as File);
  formData.append("title", payload.file.name);
  formData.append("categoryid", payload.categoryid);
  formData.append("visibleonmobile", payload.visibleonmobile);
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.post<{id: string}>(path, formData, config);
  return data;
};

//Note - delete can't be used as a variable declaration name, so we use deleteItem
const deleteItem: DeleteFunction<Document> = async (id, jwt?, cancelToken?) => {
  const path = `/${id}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  await axios.delete(path, config);
};

const getCategories: QueryFunction<Category> = async ({}, jwt?, cancelToken?) => {
  const path = "/categories";
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: jwt ? jwt : "",
    },
    cancelToken,
  };
  const {data} = await axios.get(path, config);
  return data;
}

export const api = {
  query,
  download,
  upload,
  deleteItem,
  getCategories,
};
