import { useCallback } from "react";
import { Mode } from "../../components/general/types/Modify";
import { createDropdownOptions } from "../../helpers/dropdown-helpers";
import { useToken } from "../../hooks/authentication/useToken";
import { paths } from "../../navigation/paths";
import { assetStrings as strings } from "../../resources/strings/assets";
import { api } from "../../services/assets.service";
import type { RouteProps, ValidationConstraints } from "../../types";
import { Asset } from "../../types/documents/Asset";
import { ModifyContainer } from "../general/ModifyContainer";
import InspectionsList from "../inspections/InspectionList";
import { AssetsConfig } from "./AssetsConfig";
export interface Props extends RouteProps {
  mode: Mode;
}

type Component = (props: Props) => JSX.Element;
type ListItems = {[key: string]: any}[];

const constraints: ValidationConstraints<Asset> = {
  Notes: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.notes} is required`,
    },
  },
  AssetBarcode: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.barcode} is required`,
    },
  },
  LocationId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.location} is required`,
    },
  },
  LocationDepth: {
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 4,
      lessThanOrEqualTo: 4,
      message: `^${strings.labels.location} must be a room`,
    },
  },
  AssetTypeId: {
    presence: {
      allowEmpty: false,
      message: `^${strings.labels.assetType} is required`,
    },
  },
};

export const AssetModify: Component = ({mode, ...routeProps}) => {
  const jwt = useToken();

  const getAssetTypes = useCallback(async () => {
    const assetTypes: ListItems = [{Key: "1"}];
    if (!jwt) {
      return createDropdownOptions(assetTypes, "Key", "1");
    }
    const items = await api.getAssetTypes({size: 100000}, jwt);
    return createDropdownOptions(items.items, "Id", "Name");
  }, [jwt]);

  return (
    <div>
      <ModifyContainer<Asset>
        {...routeProps}
        api={api}
        mode={mode}
        initialData={{
          AssetId: "",
          Id: "",
          Notes: "",
          AssetBarcode: "",
          LocationId: "",
          AssetTypeId: "",
        }}
        componentConfiguration={(props) => AssetsConfig(props, getAssetTypes, getAssetTypes)}
        constraints={constraints}
        redirectPath={paths.assets.list}
      />
      {
        mode === "update" || mode === "view" ? (
          <InspectionsList permissions={null} user={null}  />
        ) : null
      }
      
    </div>
  );
};

export default AssetModify;
