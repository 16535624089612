export const issueStrings = {
    headers: {
        issuesList: "Issues",  
    },
    labels: {
        search: "Search Inspections",
        issueType: "Issue Type",
        description: "Description",
        
    },
    placeholders: {

    },
    text: {

    },
  };